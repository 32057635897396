import "./styles.css";
import Typography from "@mui/material/Typography";
import CopyIcon from "components/CopyIcon";
import { Fade, Tooltip } from "@mui/material";
import { useEffect, useState, memo } from "react";
import { TransformedTemplate } from "hooks/useGlobalIndex/types";
import TargetInstances from "./TargetInstances";
import convertUint8ArrayToString from "utils/convertUint8ArrayToString";
import { toast } from "react-toastify";
import TableCell from "@mui/material/TableCell";
import IconButton from "@mui/material/IconButton";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import Collapse from "@mui/material/Collapse";
import Tables from "./Tables";
import yaml from "js-yaml";
import checkIfTablesVisible from "utils/checkIfTablesVisible";
import CardTitle from "./CardTitle";
import { REACT_APP_CLAIM_TABLES } from "conf";
import {
  L2TxLogMuiCard,
  MOBILE_WIDTH,
} from "components/TableContainer/CustomizedMui";
import handleTxIds from "utils/handleTxIds";
import { useExtractFilesFromUrl } from "hooks/useExtractFilesFromUrl";
type ContactProps = {
  width: number;
  data: TransformedTemplate;
};

interface FileObject {
  [key: string]: Uint8Array;
}

const Contract = (props: ContactProps) => {
  const { width, data } = props;
  const [onchainData, setOnchainData] = useState<string | null>(null);
  const [engine, setEngine] = useState<object | null>(null);
  const [description, setDescription] = useState<string | null>(null);
  const [keywords, setKeywords] = useState<string | null>(null);
  const [homepage, setHomepage] = useState<string | null>(null);
  const [bugsUrl, setbugsUrl] = useState<string | null>(null);
  const [openOnchain, setOpenOnchain] = useState(false);
  const [openTargetInstances, setOpenTargetInstances] = useState(false);
  const [tablesOpen, setTablesOpen] = useState(false);
  const [yamlData, setYamlData] = useState<any>(null);
  const { files } = useExtractFilesFromUrl(
    data.details.pkg_mirror.url
  );

  useEffect(() => {
    if (
      files !== null &&
      typeof files === "object" &&
      !Array.isArray(files) &&
      Object.keys(files).length > 0
    ) {
      for (const [key, value] of Object.entries(files)) {
        if (key === "package/cweb_dist/onchain/index.js") {
          setOnchainData(convertUint8ArrayToString(value));
        } else if (key === "package/package.json") {
          const packageJsonData = JSON.parse(convertUint8ArrayToString(value));

          if (packageJsonData.engines) {
            const engines = packageJsonData.engines;
            setEngine(engines);
          }
          if (packageJsonData.homepage) {
            setHomepage(packageJsonData.homepage);
          }
          if (packageJsonData.description) {
            setDescription(packageJsonData.description);
          }
          if (packageJsonData.keywords) {
            setKeywords(packageJsonData.keywords);
          }
          if (packageJsonData.bugs?.url) {
            setbugsUrl(packageJsonData.bugs.url);
          }
        }
      }
    }
  }, [files]);

  useEffect(() => {
    fetch("/mock.yaml")
      .then((response) => response.text())
      .then((text) => {
        try {
          const data = yaml.load(text); // Load and parse YAML content
          setYamlData(data);
        } catch (e) {
          console.error("Error parsing YAML:", e);
        }
      })
      .catch((error) => {
        console.error("Error loading YAML file:", error);
      });
  }, []);

  function Row(
    row: any,
    title: string,
    code: boolean,
    isOpen: boolean,
    toggleOpen: (val: boolean) => void
  ) {
    const handleLineClick = (line: string) => {
      navigator.clipboard
        .writeText(line)
        .then(() => {
          toast.success("Copied to clipboard");
        })
        .catch((err) => {
          toast.error("Failed to copy");
          console.error("Failed to copy: ", err);
        });
    };

    return (
      <div style={{ width: "100%" }}>
        <TableCell
          onClick={() => toggleOpen(!isOpen)}
          className="operationCollapseCell"
          sx={{ width: "100%" }}
        >
          <IconButton aria-label="expand row" size="small">
            {isOpen ? (
              <KeyboardArrowUpIcon sx={{ color: "rgb(0, 195, 255)" }} />
            ) : (
              <KeyboardArrowDownIcon sx={{ color: "rgb(0, 195, 255)" }} />
            )}
          </IconButton>
          <Typography className="BoldBlueFont16">{title}</Typography>
        </TableCell>
        <TableCell
          sx={{ width: "100% !important" }}
          className="operationCollapseCell"
        >
          <Collapse
            onClick={() => code && handleLineClick(row)}
            in={isOpen}
            timeout="auto"
            unmountOnExit
            sx={{ width: "100% !important" }}
          >
            {code ? (
              <div
                id="collapse_container"
                className="BoldFont14 scrollable-content"
              >
                <pre id="code">{row}</pre>
              </div>
            ) : (
              <div>
                {(row && Array.isArray(row) && row.length === 0) ||
                !row ||
                row === null ||
                row === "" ? (
                  <Typography className="BoldFont14" align="center">
                    not provided
                  </Typography>
                ) : (
                  <TargetInstances data={row}></TargetInstances>
                )}
              </div>
            )}
          </Collapse>
        </TableCell>
      </div>
    );
  }

  return (
    <div
      className="L2txLogCard-container"
      style={{
        marginBottom: width > MOBILE_WIDTH ? "0px" : "20px",
        marginTop: width > MOBILE_WIDTH ? "0px" : undefined,
        height: "100%",
      }}
    >
      <L2TxLogMuiCard sx={{ minWidth: 100, padding: "20px" }}>
        <CardTitle title="Coinweb Contract Template" />

        <div className="grid_container">
          {data?.details?.contract_module && (
            <div>
              <Typography className="BoldFont16" align="left">
                Contract Module Id
              </Typography>
              <div className="L2TxId-row">
                <Typography className="BoldFont14" align="center">
                  <>
                    <Tooltip
                      componentsProps={{
                        tooltip: {
                          sx: {
                            width: "min-content !important",
                            backgroundColor: "#4f4f72 !important",
                            padding: "0px !important",
                            marginTop: "-1px !important",
                          },
                        },
                      }}
                      disableFocusListener
                      TransitionComponent={Fade}
                      title={
                        <div id="TxId-tooltip">
                          {data?.details?.contract_module}
                        </div>
                      }
                    >
                      <div className="L2TxId-container">
                        {handleTxIds(data?.details?.contract_module)}
                      </div>
                    </Tooltip>
                  </>
                </Typography>
                <CopyIcon data={data?.details?.contract_module} />
              </div>
            </div>
          )}
          {data?.alias && (
            <div>
              <Typography className="BoldFont16" align="left">
                Contract Alias
              </Typography>
              <div className="L2TxId-row">
                <Typography className="BoldFont14" align="center">
                  {data?.alias}
                </Typography>
              </div>
            </div>
          )}
          {data?.details?.pkg_mirror?.url && (
            <div>
              <Typography className="BoldFont16" align="left">
                Javascript Package
              </Typography>
              <Typography className="BoldFont14" align="center">
                <a
                  className="BoldFont14"
                  href={`https://npm.coinweb.io/-/web/detail/cweb_${data.details.contract_module}`}
                  target="_blank"
                >
                  Click here to open npm link
                </a>
              </Typography>
            </div>
          )}
          <div>
            <Typography className="BoldFont16" align="left">
              Engines
            </Typography>
            <div className="L2TxId-row">
              <Typography
                className="BoldFont14"
                align="center"
                sx={{
                  overflowWrap: "anywhere",
                }}
              >
                {engine !== null
                  ? Object.entries(engine).map(
                      ([key, value]) => `${key}: ${value} `
                    )
                  : "not provided"}
              </Typography>
            </div>
          </div>
          <div>
            <Typography className="BoldFont16" align="left">
              Description
            </Typography>
            <div className="L2TxId-row">
              <Typography className="BoldFont14" align="center">
                {description !== null ? description : "not provided"}
              </Typography>
            </div>
          </div>
          <div>
            <Typography className="BoldFont16" align="left">
              Keywords
            </Typography>
            <div className="L2TxId-row">
              <Typography className="BoldFont14" align="center">
                {keywords !== null ? keywords : "not provided"}
              </Typography>
            </div>
          </div>
          <div>
            <Typography className="BoldFont16" align="left">
              Homepage
            </Typography>
            <div className="L2TxId-row">
              <Typography className="BoldFont14" align="center">
                {homepage !== null ? homepage : "not provided"}
              </Typography>
            </div>
          </div>
          <div>
            <Typography className="BoldFont16" align="left">
              Bugs Url{" "}
            </Typography>
            <div className="L2TxId-row">
              <Typography className="BoldFont14" align="center">
                {bugsUrl !== null ? bugsUrl : "not provided"}
              </Typography>
            </div>
          </div>
        </div>

        {onchainData &&
          Row(
            onchainData,
            "Smart Contract Onchain Code",
            true,
            openOnchain,
            setOpenOnchain
          )}
        <div>
          {Row(
            data?.details?.target_instances,
            "Known Instances",
            false,
            openTargetInstances,
            setOpenTargetInstances
          )}
        </div>
        {checkIfTablesVisible(yamlData, true) &&
          REACT_APP_CLAIM_TABLES === "true" && (
            <div>
              <TableCell
                className="operationCollapseCell"
                sx={{ width: "100%" }}
              >
                <div
                  className="operationCollapseCell"
                  onClick={() => setTablesOpen(!tablesOpen)}
                  style={{ cursor: "pointer" }}
                >
                  <IconButton aria-label="expand row" size="small">
                    {tablesOpen ? (
                      <KeyboardArrowUpIcon sx={{ color: "rgb(0, 195, 255)" }} />
                    ) : (
                      <KeyboardArrowDownIcon
                        sx={{ color: "rgb(0, 195, 255)" }}
                      />
                    )}
                  </IconButton>
                  <Typography className="BlueFont14">Tables</Typography>
                </div>
              </TableCell>
              <TableCell
                sx={{ width: "100% !important" }}
                className="operationCollapseCell"
              >
                <Collapse
                  in={tablesOpen}
                  timeout="auto"
                  unmountOnExit
                  sx={{ width: "100%" }}
                >
                  <Tables
                    isTemplate={true}
                    txid={data.details.contract_module}
                    data={yamlData}
                  />
                </Collapse>
              </TableCell>
            </div>
          )}
      </L2TxLogMuiCard>
    </div>
  );
};
export default memo(Contract);
