import "./styles.css";
import Typography from "@mui/material/Typography";
import Check from "assets/Icons/check.svg";
import {
  cweb_api,
  NetworkName,
  SmartContractRef,
  Claims,
} from "@coinweb/claims-client";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  MenuItem,
  Select,
  Fade,
  IconButton,
} from "@mui/material";
import { ClaimTablesData, ClaimsByTxId } from "utils/types";
import CloseIcon from "@mui/icons-material/Close";
import DoneIcon from "@mui/icons-material/Done";
import { memo, useEffect, useState, useMemo } from "react";
import {
  TabFormControl,
  SelectIcon,
  MetamaskButton,
  SearchPaper,
  SearchInputBase,
  TabMenuDivider,
  ContainerDivider,
  BlockChainArray,
} from "../TableContainer/CustomizedMui";
import DeactiveSwipe from "../../assets/Icons/icon-swipe-deactive.svg";
import ActiveSwipe from "../../assets/Icons/icon-swipe-active.svg";
import { BlockChains } from "utils/constants";
import { theme } from "../TableContainer/TableContainer";
import { BlockChain } from "components/TableContainer/CustomizedMui";
import { NetworkName as gqlNetworkName } from "generated/graphql";
import { NetworkName as devNetworkName } from "generated/graphql-devnet";
import { REACT_APP_CWEB_API_URL } from "conf";
type TablesProps = {
  data: any;
  txid: string;
  isTemplate: boolean;
};
const getBlockchainsByHover = (hoverString: NetworkName | string) => {
  const normalizedHoverString = String(hoverString)
    .replace(/\s+/g, "")
    .toLowerCase();
  for (const key in BlockChains) {
    if (BlockChains.hasOwnProperty(key)) {
      const blockchain =
        BlockChains[key as unknown as gqlNetworkName | devNetworkName];

      const normalizedBlockchainHover = blockchain.hover
        .replace(/\s+/g, "")
        .toLowerCase();

      if (normalizedBlockchainHover === normalizedHoverString) {
        return blockchain;
      }
      if (
        (normalizedBlockchainHover === "multiversx" &&
          normalizedHoverString === "elrond") ||
        (normalizedBlockchainHover === "multiversxtestnet" &&
          normalizedHoverString === "elrondtestnet") ||
        (normalizedBlockchainHover === "binance" &&
          normalizedHoverString === "bnb") ||
        (normalizedBlockchainHover === "binancetestnet" &&
          normalizedHoverString === "bnbtestnet")
      ) {
        return blockchain;
      }
    }
  }
};
const Tables = (props: TablesProps) => {
  const { data, txid, isTemplate } = props;

  const [claims, setClaims] = useState<ClaimsByTxId>();
  const [offsetArray, setOffsetArray] = useState<number[]>(
    new Array(data.tables.length).fill(0)
  );
  const [selectedNetwork, setSelectedNetwork] = useState<number[]>(
    new Array(data.tables.length).fill(0)
  );
  const [selectedNetworkState, setSelectedNetworkState] = useState<boolean[]>(
    new Array(data.tables.length).fill(false)
  );
  const [selectBlockchainOptions, setSelectBlockchainOptions] = useState<
    BlockChain[]
  >([]);

  const cweb = useMemo(
    () => cweb_api(REACT_APP_CWEB_API_URL ? REACT_APP_CWEB_API_URL : ""),
    []
  );

  const increaseArrayBy20 = (index: number) => {
    setOffsetArray((prevArray) =>
      prevArray.map((num: number, i) => (i === index ? num + 20 : num))
    );
  };
  const decreaseArrayBy20 = (index: number) => {
    setOffsetArray((prevArray) =>
      prevArray.map((num: number, i) =>
        i === index && num - 20 < 0 ? num - 20 : num
      )
    );
  };

  useEffect(() => {
    const fetchClaims = async () => {
      try {
        await cweb.update_tip();

        let newClaimsByTxId: ClaimsByTxId = {};

        for (let i = 0; i < data.tables.length; i++) {
          if (!newClaimsByTxId[txid]) {
            newClaimsByTxId[txid] = {};
          }

          const networkNames = Object.values(NetworkName);

          await Promise.all(
            (networkNames as unknown as NetworkName[]).map(async (network) => {
              const shard = cweb.shard(network);

              // const contract_id: SmartContractRef = {
              //   ComputationBlockContract:
              //     "0xdd58a0f13231e488e1440d295fa2b7c07f6bae1e7250e5f0fe5585ff2943cc95",
              // };
              const contract_id: SmartContractRef = {
                ComputationBlockContract: "0x" + txid,
              };

              const contract = shard.contract(contract_id);

              // const fpk = ["STATE"];
              const fpk = data.tables[i].claim.first_key;

              if (fpk) {
                const claimResult = await contract.get_all(
                  fpk,
                  offsetArray[i],
                  20
                );
                if (!newClaimsByTxId[txid][network]) {
                  newClaimsByTxId[txid][network] = {
                    claims: {
                      data: claimResult,
                      offset: offsetArray[i],
                    },
                  };
                } else {
                  const existingEntry = newClaimsByTxId[txid][network];
                  if (existingEntry) {
                    existingEntry.claims.data = claimResult;
                    existingEntry.claims.offset = offsetArray[i];
                  }
                }
              }
            })
          );
        }

        setClaims(newClaimsByTxId);
      } catch (error) {
        console.error("Error fetching claims:", error);
      }
    };

    fetchClaims();
  }, [data.tables, offsetArray, cweb]);
  useEffect(() => {
    const newBlockchainArray: BlockChain[] = [];
    let ind = 0;
    for (const [, key] of Object.keys(NetworkName).entries()) {
      const blockchain = getBlockchainsByHover(key);
      if (blockchain) {
        newBlockchainArray.push(blockchain);
        ind = ind + 1;
      }

      if (blockchain?.label === "BNB") {
        setSelectedNetwork(new Array(data.tables.length).fill(ind - 1));
      }
    }
    setSelectBlockchainOptions(newBlockchainArray);
  }, [setSelectBlockchainOptions]);
  console.log("blcokchainopt", selectBlockchainOptions);
  return (
    <div>
      {data.tables &&
        data.tables.map((table: any, index: number) => {
          if (
            (isTemplate && table?.availability.template) ||
            (!isTemplate && table?.availability.instance)
          )
            return (
              <div
                key={index}
                style={{
                  width: "calc(100%) - 20px",
                  backgroundColor: "#20214a",
                  borderRadius: "6px",
                  marginBottom: "20px",
                  padding: "10px",
                }}
              >
                <div className="grid_container">
                  <div className="grid_cell" style={{ margin: "10px" }}>
                    <Typography className="BoldWhiteFont14" align="left">
                      Alias
                    </Typography>
                    <div className="L2TxId-row">
                      <Typography className="BoldFont14" align="center">
                        {table?.alias ? table?.alias : "not provided"}
                      </Typography>
                    </div>
                  </div>
                  <div className="grid_cell" style={{ margin: "10px" }}>
                    <Typography className="BoldWhiteFont14" align="left">
                      Name
                    </Typography>
                    <div className="L2TxId-row">
                      <Typography className="BoldFont14" align="center">
                        {table?.name ? table?.name : "not provided"}
                      </Typography>
                    </div>
                  </div>
                  <div className="grid_cell" style={{ margin: "10px" }}>
                    <Typography className="BoldWhiteFont14" align="left">
                      Description
                    </Typography>
                    <div className="L2TxId-row">
                      <Typography className="BoldFont14" align="center">
                        {table?.description
                          ? table?.description
                          : "not provided"}
                      </Typography>
                    </div>
                  </div>
                </div>
                <TabFormControl sx={{ maxWidth: "500px" }}>
                  <ThemeProvider theme={theme}>
                    <Select
                      open={selectedNetworkState[index]}
                      value={selectedNetwork[index]}
                      onChange={(e) => {
                        setSelectedNetwork((prevArray) =>
                          prevArray.map((num, i) =>
                            i === index
                              ? (e.target.value as unknown as number)
                              : num
                          )
                        );
                      }}
                      displayEmpty
                      inputProps={{ "aria-label": "Without label" }}
                      defaultValue={0}
                      IconComponent={SelectIcon}
                      onOpen={() =>
                        setSelectedNetworkState((prevState) => {
                          const newState = [...prevState];
                          newState[index] = true;
                          return newState;
                        })
                      }
                      onClose={() =>
                        setSelectedNetworkState((prevState) => {
                          const newState = [...prevState];
                          newState[index] = false;
                          return newState;
                        })
                      }
                    >
                      {selectBlockchainOptions.map((network, ind) => {
                        return (
                          <MenuItem key={ind} value={ind}>
                            <div className="Select-item-wrapper">
                              <div
                                style={{
                                  display: "flex",
                                  marginBottom: "0px !important",
                                }}
                              >
                                <div className="Select-icon-container">
                                  <img
                                    width={29}
                                    src={network.icon}
                                    alt={network.hover}
                                  ></img>
                                </div>
                                <div
                                  className="Select-value"
                                  style={{
                                    color:
                                      ind === selectedNetwork[index]
                                        ? "#ffffff"
                                        : undefined,
                                  }}
                                >
                                  {network.hover} - {network.label}
                                </div>
                                {ind === selectedNetwork[index] && (
                                  <div
                                    style={{
                                      marginLeft: "auto",
                                      alignSelf: "center",
                                    }}
                                  >
                                    <div className="Select-icon-selected">
                                      <img
                                        style={{ width: "17px" }}
                                        src={Check}
                                        alt="check"
                                      ></img>
                                    </div>
                                  </div>
                                )}
                              </div>
                              {ind ===
                              selectBlockchainOptions.length - 1 ? null : (
                                <div id="Select-divider">
                                  <TabMenuDivider variant="middle" />
                                </div>
                              )}
                            </div>
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </ThemeProvider>
                </TabFormControl>
                <div className="L2TxId-row" style={{ width: "100%" }}>
                  <TableContainer id="Block-Data-Table-Mui">
                    <Table
                      sx={{ borderColor: "red" }}
                      aria-label="collapsible table"
                    >
                      <TableHead>
                        <TableRow>
                          <TableCell className="Table-Header" align="center">
                            Key
                          </TableCell>
                          <TableCell className="Table-Header" align="center">
                            Body
                          </TableCell>
                          <TableCell className="Table-Header" align="left">
                            Fees Stored
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {/* {claims && claims[index]?.claims.length > 0 ? (
                          claims[index].claims.map((row: any, idx: number) => (
                            <TableRow key={idx}>
                              <TableCell>{row.blockchain}</TableCell>
                              <TableCell>{row.height}</TableCell>
                              <TableCell>{row.time}</TableCell>
                            </TableRow>
                          ))
                        ) : (
                          <TableRow>
                            <TableCell colSpan={3}>No Claims Found</TableCell>
                          </TableRow>
                        )} */}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </div>
                <div
                  id="Loadmore-container"
                  style={{ display: "flex", justifyContent: "space-evenly" }}
                >
                  <div
                    className="Carousel-icon-container"
                    onClick={() => decreaseArrayBy20(index)}
                  >
                    <div className="Carousel-icon">
                      <img
                        src={
                          selectedNetwork[index] === 0
                            ? DeactiveSwipe
                            : ActiveSwipe
                        }
                        alt="swipe"
                      ></img>
                    </div>
                  </div>
                  <div
                    className="Carousel-icon-container"
                    onClick={() => increaseArrayBy20(index)}
                  >
                    <div className="Carousel-icon">
                      <img
                        className="Right-icon-img"
                        src={ActiveSwipe}
                        alt="swipe"
                      ></img>
                    </div>
                  </div>
                </div>
              </div>
            );
          return undefined;
        })}
    </div>
  );
};

export default memo(Tables);
